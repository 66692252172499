import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDraggableModal',{ref:_vm.modal_name,attrs:{"name":_vm.modal_name,"max-height":"80vh","width":"70vw","fullscreen":_vm.$vuetify.breakpoint.xsOnly}},[_c(VCard,{attrs:{"loading":_vm.loading,"disabled":_vm.loading}},[_c('BaseModal',[_c(VToolbar,{staticClass:"draggable_selector",attrs:{"slot":"header","dark":"","color":"primary","dense":"","flat":""},slot:"header"},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.reset.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.page.title))]),_c(VSpacer),_c(VBtn,{attrs:{"text":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.save.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-content-save")])],1)],1),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c(VDivider),_c(VCardText,{staticClass:"pt-5"},[_vm._l((_vm.usual_fields),function(field){return [_c('base-field',{key:field.name,attrs:{"value":_vm.record[field.name],"field":field},on:{"change":_vm.usualFieldDataChanged}})]}),_vm._l((_vm.custom_fields),function(field){return [_c('base-field',{key:field.name,attrs:{"value":_vm.record.custom_fields[field.name],"field":field},on:{"change":_vm.customFieldDataChanged}})]})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }